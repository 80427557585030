// tslint:disable-next-line:no-shadowed-variable
import { Roles } from '../content/pages/models/constants/role';
import { ConfigModel } from '../core/interfaces/config';

// tslint:disable-next-line:no-shadowed-variable
export class MenuConfig implements ConfigModel {
	public config: any = {};

	constructor() {
		this.config = {
			aside: {
				self: {},
				items: [
					{
						title: 'Dashboard',
						desc: 'Dashboard of wells',
						root: true,
						icon: 'flaticon-line-graph',
						page: '/',
						translate: 'MENU.DASHBOARD'
					},
					/*
					{
						title: 'Map',
						desc: 'Map of fields and lines',
						root: true,
						icon: 'flaticon-line-graph',
						page: '/map',
						translate: 'MENU.MAP'
					},*/
					{
						title: 'ReservationIrrigation',
						desc: 'Page to create well reservation',
						root: true,
						icon: 'la la-tint',
						page: '/wellreservation',
						translate: 'MENU.RESERVATION',
						roles: [Roles.ADMIN, Roles.COOP_GUARD, Roles.COOP_ACCOUNTANT, Roles.COOP, Roles.COOP_ADMIN, Roles.FARMER],
					},
					{
						title: 'Directorates',
						desc: 'Pages to manage directorates',
						root: true,
						icon: 'flaticon-suitcase',
						translate: 'MENU.DIRECTORATES.MAIN',
						roles: [Roles.ADMIN, Roles.DIRECTORATE_ADMIN],
						submenu: [
							{
								title: 'Manage Directorates',
								desc: 'Page to manage directorates',
								root: true,
								icon: 'flaticon-settings',
								page: '/directorates',
								translate: 'MENU.DIRECTORATES.MANAGE',
								roles: [Roles.ADMIN],
							},
							{
								title: 'Manage Directorate Users',
								desc: 'Page to manage directorate users',
								root: true,
								icon: 'flaticon-users',
								page: '/users/directorate',
								translate: 'MENU.DIRECTORATES.USERS',
							}
						]
					},
					{
						title: 'Coops',
						desc: 'Pages to manage coops',
						root: true,
						icon: 'flaticon-truck',
						translate: 'MENU.COOPS.MAIN',
						roles: [Roles.ADMIN, Roles.DIRECTORATE, Roles.DIRECTORATE_ADMIN, Roles.COOP_ADMIN],
						submenu: [
							{
								title: 'Coop Settings',
								desc: 'Page to manage coop settings',
								root: true,
								icon: 'flaticon-settings',
								page: '/coopsettings',
								translate: 'MENU.COOPS.SETTINGS',
								roles: [Roles.COOP_ADMIN]
							},
							{
								title: 'Manage Coops',
								desc: 'Page to manage coops',
								root: true,
								icon: 'flaticon-settings',
								page: '/coops',
								translate: 'MENU.COOPS.MANAGE',
								roles: [Roles.ADMIN, Roles.DIRECTORATE, Roles.DIRECTORATE_ADMIN]
							},
							{
								title: 'Manage Wells',
								desc: 'Page to manage wells',
								root: true,
								icon: 'la la-dot-circle-o',
								page: '/wells',
								translate: 'MENU.COOPS.WELLS',
							},
							{
								title: 'Manage Hydrants',
								desc: 'Page to manage hydrants',
								root: true,
								icon: 'flaticon-squares-4',
								page: '/hydrants',
								translate: 'MENU.COOPS.HYDRANTS',
							},
							{
								title: 'Manage Tanks',
								desc: 'Page to manage tanks',
								root: true,
								icon: 'flaticon-squares-4',
								page: '/tanks',
								translate: 'MENU.COOPS.TANKS',
							},
							{
								title: 'Manage Irrigation Lines',
								desc: 'Page to manage irrigation lines',
								root: true,
								icon: 'la la-minus-square',
								page: '/lines',
								translate: 'MENU.COOPS.LINES',
								roles: [Roles.ADMIN]
							},
							/*
							{
								title: 'View Irrigation Mains',
								desc: 'Page to view irrigation mains',
								root: true,
								icon: 'flaticon-download',
								page: '/mains',
								translate: 'MENU.COOPS.MAINS',
								roles: [Roles.ADMIN]
							},
							*/
							{
								title: 'Manage Price Tariffs',
								desc: 'Page to manage price tariffs',
								root: true,
								icon: 'la la-money',
								page: '/priceTariffs',
								translate: 'MENU.COOPS.PRICETARIFFS',
							},
							{
								title: 'Manage Fields',
								desc: 'Page to manage fields',
								root: true,
								icon: 'flaticon-layers',
								page: '/fields',
								translate: 'MENU.COOPS.FIELDS',
							},
							{
								title: 'Manage Tokens',
								desc: 'Page to manage tokens',
								root: true,
								icon: 'la la-key',
								page: '/cooptoken',
								translate: 'IRRIMET.TOKENS.TOKENS_LIST',
								roles: [Roles.COOP_ADMIN],
							},
							{
								title: 'Manage Coop Users',
								desc: 'Page to manage coop users',
								root: true,
								icon: 'flaticon-users',
								page: '/users/coop',
								translate: 'MENU.COOPS.USERS',
								roles: [Roles.ADMIN, Roles.COOP, Roles.COOP_ADMIN]
							},
						]
					},
					{
						title: 'Members',
						desc: 'Pages to manage members',
						root: true,
						icon: 'flaticon-customer',
						translate: 'MENU.MEMBERS.MAIN',
						roles: [Roles.ADMIN, Roles.DIRECTORATE, Roles.DIRECTORATE_ADMIN, Roles.COOP_ADMIN, Roles.COOP_ACCOUNTANT],
						submenu: [
							{
								title: 'Manage Members',
								desc: 'Page to manage members',
								root: true,
								icon: 'flaticon-settings',
								page: '/members',
								translate: 'MENU.MEMBERS.MANAGE',
								roles: [Roles.ADMIN, Roles.DIRECTORATE, Roles.DIRECTORATE_ADMIN, Roles.COOP_ADMIN, Roles.COOP_ACCOUNTANT]
							},
							{
								title: 'Manage Member Users',
								desc: 'Page to manage member users',
								root: true,
								icon: 'flaticon-users',
								page: '/users/member',
								translate: 'MENU.MEMBERS.USERS',
								roles: [Roles.ADMIN, Roles.COOP_ADMIN]
							},
						]
					},
					{
						title: 'Water Automation System',
						desc: 'Page to display and manage water automation system',
						root: true,
						icon: 'la la-minus-square',
						page: '/automations',
						translate: 'MENU.AUTOMATIONS.MENU_NAME',
						roles: [Roles.ADMIN,Roles.COOP_ADMIN]
					},
					{
						title: 'Manage Crops',
						desc: 'Page to manage crops',
						root: true,
						icon: 'la la-leaf',
						page: '/crops',
						translate: 'MENU.CROPS',
						roles: [Roles.ADMIN, Roles.DIRECTORATE, Roles.DIRECTORATE_ADMIN,  Roles.COOP_ADMIN]
					},
					{
						title: 'Frequently Asked Questions',
						desc: 'Page to display and manage frequently asked questions',
						root: true,
						icon: 'la la-question-circle',
						page: '/faq',
						translate: 'MENU.FAQ',
						roles: [Roles.ADMIN, Roles.DIRECTORATE, Roles.DIRECTORATE_ADMIN, Roles.COOP_GUARD,Roles.COOP_ACCOUNTANT, Roles.COOP, Roles.COOP_ADMIN, Roles.FARMER]
					},
					{
						title: 'Announcements',
						desc: 'Page to manage announcements',
						root: true,
						icon: 'la la-bullhorn',
						page: '/announcements',
						translate: 'MENU.ANNOUNCEMENTS',
						roles: [Roles.ADMIN, Roles.DIRECTORATE, Roles.DIRECTORATE_ADMIN, Roles.COOP_GUARD,Roles.COOP_ACCOUNTANT, Roles.COOP, Roles.COOP_ADMIN, Roles.FARMER]
					},
					{
						title: 'Forms',
						desc: 'Pages to view the forms and receipts',
						root: true,
						icon: 'flaticon-folder',
						translate: 'MENU.FORMS.MAIN',
						roles: [Roles.ADMIN, Roles.COOP_ADMIN,Roles.COOP_ACCOUNTANT],
						submenu: [
							{
								title: 'Receipt Book',
								desc: 'Receipt Book',
								root: true,
								icon: 'flaticon-folder',
								page: '/receiptBook',
								translate: 'MENU.FORMS.RECEIPT_BOOK',
								roles: [Roles.ADMIN, Roles.COOP_ADMIN, Roles.COOP_ACCOUNTANT],
							},
							{
								title: 'Irrigation Forms',
								desc: 'Irrigation Forms',
								root: true,
								icon: 'flaticon-folder',
								page: '/irrigationForm',
								translate: 'MENU.FORMS.IRRIGATION_FORM',
								roles: [Roles.ADMIN, Roles.COOP_ADMIN, Roles.COOP_ACCOUNTANT],
							},
							{
								title: 'Monthly Summary',
								desc: 'Monthly Summary',
								root: true,
								icon: 'flaticon-folder',
								page: '/monthlySummary',
								translate: 'MENU.FORMS.MONTHLY_SUMMARY',
								roles: [Roles.ADMIN, Roles.COOP_ADMIN, Roles.COOP_ACCOUNTANT],
							},
						]
					},
					{
						title: 'Reports',
						desc: 'Pages to generate and view reports',
						root: true,
						icon: 'flaticon-diagram',
						translate: 'MENU.REPORTS.MAIN',
						roles: [Roles.ADMIN, Roles.DIRECTORATE_ADMIN, Roles.COOP_ADMIN, Roles.FARMER, Roles.COOP_ACCOUNTANT],
						submenu: [
							{
								title: 'Well Consumption Report',
								desc: 'Report for well/hydrant production',
								root: true,
								icon: 'flaticon-diagram',
								page: '/wellConsumptionReport',
								translate: 'MENU.REPORTS.WELL_PRODUCTION',
								roles: [Roles.ADMIN, Roles.DIRECTORATE_ADMIN, Roles.COOP_ADMIN, Roles.COOP_ACCOUNTANT],
							},
							{
								title: 'Member Production Report',
								desc: 'Report for member based production',
								root: true,
								icon: 'flaticon-diagram',
								page: '/wellMemberConsumptionReport',
								translate: 'MENU.REPORTS.MEMBER_PRODUCTION',
								roles: [Roles.ADMIN, Roles.DIRECTORATE_ADMIN, Roles.COOP_ADMIN, Roles.COOP_ACCOUNTANT],
							},
							{
								title: 'Field Production Report',
								desc: 'Report for field based production',
								root: true,
								icon: 'flaticon-diagram',
								page: '/fieldConsumptionReport',
								translate: 'MENU.REPORTS.FIELD_PRODUCTION',
								roles: [Roles.ADMIN, Roles.DIRECTORATE_ADMIN, Roles.COOP_ADMIN, Roles.COOP_ACCOUNTANT],
							},
							{
								title: 'Field-Well Consumption Report',
								desc: 'Report for field-well consumption',
								root: true,
								icon: 'flaticon-diagram',
								page: '/fieldWellConsumptionReport',
								translate: 'MENU.REPORTS.FIELD_WELL_CONSUMPTION',
								roles: [Roles.ADMIN, Roles.DIRECTORATE_ADMIN, Roles.COOP_ADMIN, Roles.COOP_ACCOUNTANT],
							},
							{
								title: 'Member Consumption Report',
								desc: 'Report for member consumption',
								root: true,
								icon: 'flaticon-diagram',
								page: '/memberConsumptionReport',
								translate: 'MENU.REPORTS.MEMBER_CONSUMPTION',
								roles: [Roles.ADMIN, Roles.DIRECTORATE_ADMIN, Roles.COOP_ADMIN, Roles.FARMER, Roles.COOP_ACCOUNTANT],
							},
							{
								title: 'Member Cash Report',
								desc: 'Report for member cash',
								root: true,
								icon: 'flaticon-diagram',
								page: '/memberCashReport',
								translate: 'MENU.REPORTS.MEMBER_CASH',
								roles: [Roles.ADMIN, Roles.COOP_ADMIN, Roles.FARMER, Roles.COOP_ACCOUNTANT],
							},
							{
								title: 'Income Expense Report',
								desc: 'Report for income expense',
								root: true,
								icon: 'flaticon-diagram',
								page: '/incomeExpenseReport',
								translate: 'MENU.REPORTS.INCOME_EXPENSE',
								roles: [Roles.ADMIN, Roles.COOP_ADMIN, Roles.COOP_ACCOUNTANT],
							},
							{
								title: 'Irrigation History Report',
								desc: 'Report for irrigation history',
								root: true,
								icon: 'flaticon-diagram',
								page: '/irrigationHistoryReport',
								translate: 'MENU.REPORTS.IRRIGATION_HISTORY',
								roles: [Roles.ADMIN, Roles.DIRECTORATE_ADMIN, Roles.COOP_ADMIN, Roles.COOP_ACCOUNTANT],
							},
							/*
							{
								title: 'Crop Irrigation',
								desc: 'Report for water consumpton of crops',
								root: true,
								icon: 'flaticon-diagram',
								page: '/cropIrrigationReport',
								translate: 'MENU.REPORTS.CROP_IRRIGATION',
								roles: [Roles.ADMIN, Roles.DIRECTORATE,  Roles.COOP, Roles.DIRECTORATE_ADMIN, Roles.COOP_ADMIN]
							},
							*/
						]
					},
					{
						section: 'MENU.SETTINGS.MAIN',
						roles: [Roles.ADMIN]
					},
					{
						title: 'SMS Settings',
						desc: 'SMS Settings',
						root: true,
						icon: 'flaticon-chat-2',
						page: '/smsSettings',
						translate: 'MENU.SETTINGS.SMS_SETTINGS',
						roles: [Roles.ADMIN],
					},
					{
						title: 'Email Settings',
						desc: 'Email Settings',
						root: true,
						icon: 'flaticon-email',
						page: '/emailSettings',
						translate: 'MENU.SETTINGS.EMAIL_SETTINGS',
						roles: [Roles.ADMIN],
					}
				]
			}
		};
	}
}
