export interface Role {
	id: number;
	name: string;
}
export const Roles = {
    'ADMIN':                { id: 0, name: 'ADMIN' },
    'DIRECTORATE':          { id: 1, name: 'DIRECTORATE' },
    'COOPUNION':            { id: 2, name: 'COOPUNION' },
    'COOP':                 { id: 3, name: 'COOP' },
    'FARMER':               { id: 4, name: 'FARMER' },
    'DIRECTORATE_ADMIN':    { id: 101, name: 'DIRECTORATE_ADMIN' },
    'COOPUNION_ADMIN':      { id: 102, name: 'COOPUNION_ADMIN' },
    'COOP_ADMIN':           { id: 103, name: 'COOP_ADMIN' },
    'COOP_ACCOUNTANT':      { id: 203, name: 'COOP_ACCOUNTANT' },
    'COOP_GUARD':           { id: 303, name: 'COOP_GUARD' },

 };

 export class RoleConverter {
    public static convert(roleName:string):Role {
        if(roleName === Roles.ADMIN.name) {
           return Roles.ADMIN;
        } else  if(roleName === Roles.DIRECTORATE.name) {
            return Roles.DIRECTORATE;
        } else  if(roleName === Roles.COOPUNION.name) {
            return Roles.COOPUNION;
        } else  if(roleName === Roles.COOP.name) {
            return Roles.COOP;
        } else  if(roleName === Roles.FARMER.name) {
            return Roles.FARMER;
        } else  if(roleName === Roles.DIRECTORATE_ADMIN.name) {
            return Roles.DIRECTORATE_ADMIN;
        } else  if(roleName === Roles.COOPUNION_ADMIN.name) {
            return Roles.COOPUNION_ADMIN;
        } else  if(roleName === Roles.COOP_ADMIN.name) {
            return Roles.COOP_ADMIN;
        } else  if(roleName === Roles.COOP_ACCOUNTANT.name) {
            return Roles.COOP_ACCOUNTANT;
        } else  if(roleName === Roles.COOP_GUARD.name) {
            return Roles.COOP_GUARD;
        }
    }
    
}
