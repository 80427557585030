import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { MenuConfigService } from '../menu-config.service';
import { ClassInitService } from '../class-init.service';
import * as objectPath from 'object-path';
import { LayoutConfigService } from '../layout-config.service';
import { AuthenticationService } from '../../auth/authentication.service';

@Injectable()
export class MenuAsideService {
	classes: string;
	menuList$: BehaviorSubject<any[]> = new BehaviorSubject([]);

	isDropdown: number = 0;
	dropdownTimeout: number;
	isScrollable: number = 0;
	constructor(
		private menuConfigService: MenuConfigService,
		private classInitService: ClassInitService,
		private layoutConfigService: LayoutConfigService,
		private authService: AuthenticationService
	) {
		// get menu list
		this.menuConfigService.onMenuUpdated$.subscribe(model => {
			const result = objectPath.get(model.config, 'aside.items');
			setTimeout(() =>
				this.menuList$.next(this.filterMenuListAccordingToRole(objectPath.get(model.config, 'aside.items')))
			);
		});

		this.layoutConfigService.onLayoutConfigUpdated$.subscribe(config => {
			if (objectPath.get(config, 'config.aside.left.fixed')) {
				this.isScrollable = 1;
				this.isDropdown = 0;
			}

			if (!objectPath.get(config, 'config.aside.left.fixed') &&
				!objectPath.get(config, 'config.menu.aside.desktop_and_mobile.submenu.accordion')) {
				this.isScrollable = 0;
				this.isDropdown = 1;
				this.dropdownTimeout = objectPath.get(config, 'config.menu.aside.desktop_and_mobile.submenu.dropdown.hover_timeout');
			}
		});

	}

	private filterMenuListAccordingToRole(menuList) {
		const filteredMenuList = [];
		menuList.forEach(element => {
			//const subMenuListOfElement = [];
			//subMenuListOfElement.push(... element.submenu);
			if( element.submenu) {
//				const subMenuListOfElement = element.submenu;
			const subMenuListOfElement = [];
			subMenuListOfElement.push(... element.submenu);
				if (subMenuListOfElement && subMenuListOfElement.length>0) {
					element.submenu.forEach(subMenuElement => {
						if (subMenuElement.roles) {
							if (!this.authService.hasAnyOfTheRoles(subMenuElement.roles)) {
								subMenuListOfElement.splice(subMenuListOfElement.indexOf(subMenuElement), 1);
							}
						}
					} );
				}
				element.submenu = subMenuListOfElement;
			}
			if (element.roles === undefined) {
				filteredMenuList.push(element);
			} else if (element.permissions === undefined && this.authService.hasAnyOfTheRoles(element.roles)) {
				filteredMenuList.push(element);
			} else if (element.permissions && this.authService.hasAnyOfTheRoles(element.roles)) {
				if(this.authService.containsPermissions(element.permissions)) {
					filteredMenuList.push(element);
				}
			}
		});
		return filteredMenuList;
	}


}
