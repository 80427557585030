import { BaseModel } from '../../../core/models/_base.model';
import { Roles } from './constants/role';


export class Person extends BaseModel {
    public id: number;
    public name: string;
    public surname: string;
    public identityNumber: string;
    public email: string;
    public phone: string;
    public username: string;
    public userId: string;
    public directorateName: string;
    public directorateId: string;
    public directorateDescription: string;
    public coopName: string;
    public coopId: string;
    public coopDescription: string;
    public memberId: string;
    public memberName: string;
    public roles: string[] = [];
    public employeeToken = false;
    public enabled: boolean;
    static emptyPerson() {
        return new this(0, null, null, null, null, null, null
            , null, null, null, null, null, null, null, null, null, null, null);
    }
    static fromJsonResponse(data: any) {
        if (data == null) {
            return null;
        }
        let username = null;
        let userId = null;
        let directorateName = null;
        let directorateId = null;
        let directorateDescription = null;
        let coopName = null;
        let coopId = null;
        let coopDescription = null;
        let memberId = null;
        let memberName = null;
        let roles = null;
        let enabled;
        if ( data.user !== null) {
            username = data.user.username;
            userId = data.user.id;
            roles = data.user.roles;
            enabled = data.user.enabled;
        }
        if ( data.directorate !== null) {
            directorateName = data.directorate.name;
            directorateId = data.directorate.id;
            directorateDescription = data.directorate.description;
        }

        if ( data.coop !== null) {
            coopName = data.coop.name;
            coopId = data.coop.id;
            coopDescription = data.coop.description;
        }
        if ( data.member !== null) {
            memberName = data.member.name;
            memberId = data.member.id;
        }

        return new this(data.id, data.name, data.surname, data.identityNumber, data.email, data.phone, username, userId,
            directorateName, directorateId, directorateDescription,
            coopName, coopId, coopDescription,
            memberId, memberName, roles, enabled);
    }

    constructor(id: number, name: string, surname: string, identityNumber: string, email: string, phone: string,
        username: string, userId: string,
        directorateName: string, directorateId: string, directorateDescription: string,
        coopName: string, coopId: string, coopDescription: string,
        memberId: string, memberName: string, roles: any, enabled: boolean) {
        super();
        this.id = id;
        this.name = name;
        this.surname = surname;
        this.identityNumber = identityNumber;
        this.email = email;
        this.phone = phone;
        this.username = username;
        this.userId = userId;
        this.directorateName = directorateName;
        this.directorateId = directorateId;
        this.directorateDescription = directorateDescription;
        this.coopName = coopName;
        this.coopId = coopId;
        this.coopDescription = coopDescription;
        this.memberId = memberId;
        this.memberName = memberName;
        this.roles = roles;
        if (this.roles && this.roles.includes(Roles.COOP_GUARD.name)) {
            this.employeeToken = true;
        }
        this.enabled = enabled;
    }
}

