import { Observable, Subject, from } from 'rxjs';
import { map, catchError, tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'ngx-auth';

import { TokenStorage } from './token-storage.service';
import { UtilsService } from '../services/utils.service';
import { AccessData } from './access-data';
import { Credential } from './credential';
import { TOKEN_AUTH_PASSWORD, TOKEN_AUTH_USERNAME, InterceptorSkipHeader } from './auth.constant';
import { AppSettings } from './../../app.config';
import { User } from './../models/user';
import { JwtHelperService} from '@auth0/angular-jwt';
import { Role, RoleConverter, Roles } from '../../content/pages/models/constants/role';
import { MemberInfo } from './member-info';
import { EmployeeInfo } from './employee-info';
import { NumberPair } from '../../content/pages/models/constants/number-pair';
import { UnitType } from '../../content/pages/models/constants/unit-type';

@Injectable()
export class AuthenticationService implements AuthService {
	public static AUTH_TOKEN = AppSettings.BASE_URL + '/oauth/token';
	private jwtHelper: JwtHelperService;
	public accessToken: string;
    public authenticatedUser: User;
	public authenticatedUserPermissions: string[];
    public authenticatedUserParentId: number;
	public organizationAdmin: boolean;
	public accessData: AccessData;
	public authenticatedUserMemberInfo: MemberInfo[];
	public authenticatedEmployeeInfo: EmployeeInfo[];


	public unitIdRolesMapping = new Map<number, Role[]>();
	public roleUnitIdsMapping = new Map<Role, number[]>();
	public roleParentIdsMapping = new Map<Role, number[]>();
	public unitIdMapping = new Map<string, NumberPair[]>();

	API_URL = 'api';
	API_ENDPOINT_LOGIN = '/login';
	API_ENDPOINT_REFRESH = '/refresh';
	API_ENDPOINT_REGISTER = '/register';
	public onCredentialUpdated$: Subject<AccessData>;

	constructor(
		private http: HttpClient,
		private tokenStorage: TokenStorage,
		private util: UtilsService
	) {
		this.onCredentialUpdated$ = new Subject();
		this.jwtHelper = new JwtHelperService();
		let storedAccessToken;
		this.getAccessToken().subscribe(result => storedAccessToken = result);
		if (storedAccessToken != null ) {
			if (this.jwtHelper.isTokenExpired(storedAccessToken)) {
				this.logout(true);
				return;
			} else {
				this.decodeToken(storedAccessToken);
			}
		}
	}

	/**
	 * Check, if user already authorized.
	 * @description Should return Observable with true or false values
	 * @returns {Observable<boolean>}
	 * @memberOf AuthService
	 */
	public isAuthorized(): Observable<boolean> {
		let storedAccessToken;
		this.getAccessToken().subscribe(result => storedAccessToken = result);
		if (storedAccessToken === null ) {
			return null;
		}
		return this.tokenStorage.getAccessToken().pipe(map(token => !!token));
	}

	/**
	 * Get access token
	 * @description Should return access token in Observable from e.g. localStorage
	 * @returns {Observable<string>}
	 */
	public getAccessToken(): Observable<string> {
		return this.tokenStorage.getAccessToken();
	}

	/**
	 * Get user roles
	 * @returns {Observable<any>}
	 */
	public getUserRoles(): Observable<any> {
		return this.tokenStorage.getUserRoles();
	}

	/**
	 * Function, that should perform refresh token verifyTokenRequest
	 * @description Should be successfully completed so interceptor
	 * can execute pending requests or retry original one
	 * @returns {Observable<any>}
	 */
	public refreshToken(): Observable<AccessData> {
		/*
		return this.tokenStorage.getRefreshToken().pipe(
			switchMap((refreshToken: string) => {
				return this.http.get<AccessData>(this.API_URL + this.API_ENDPOINT_REFRESH + '?' + this.util.urlParam(refreshToken));
			}),
			tap(this.saveAccessData.bind(this)),
			catchError(err => {
				this.logout();
				return throwError(err);
			})
		);
		*/
		return null;
	}

	/**
	 * Function, checks response of failed request to determine,
	 * whether token be refreshed or not.
	 * @description Essentialy checks status
	 * @param {Response} response
	 * @returns {boolean}
	 */
	public refreshShouldHappen(response: HttpErrorResponse): boolean {
		return response.status === 401;
	}

	/**
	 * Verify that outgoing request is refresh-token,
	 * so interceptor won't intercept this request
	 * @param {string} url
	 * @returns {boolean}
	 */
	public verifyTokenRequest(url: string): boolean {
		return url.endsWith(this.API_ENDPOINT_REFRESH);
	}

	/**
	 * Submit login request
	 * @param {Credential} credential
	 * @returns {Observable<any>}
	 */
	public login(credential: Credential): Observable<any> {
		let httpHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
		httpHeaders = httpHeaders.set(InterceptorSkipHeader, '');
		httpHeaders = httpHeaders.append('Authorization', 'Basic ' + btoa(TOKEN_AUTH_USERNAME + ':' + TOKEN_AUTH_PASSWORD));
		let body = `username=${encodeURIComponent(credential.username)}`;
			body += `&password=${encodeURIComponent(credential.password)}`;
			body += `&grant_type=password`;

			return this.http.post<AccessData>(AuthenticationService.AUTH_TOKEN, body, { headers: httpHeaders}).pipe(
				map((result: any) => {
					if (result instanceof Array) {
						return result.pop();
					}
					return result;
				}),
				tap(this.saveAccessData.bind(this)),
				catchError(this.handleError('login', [undefined]))
			);
	}

	/**
	 * Handle Http operation that failed.
	 * Let the app continue.
	 * @param operation - name of the operation that failed
	 * @param result - optional value to return as the observable result
	 */
	private handleError<T>(operation = 'operation', result?: any) {
		return (error: any): Observable<any> => {
			return from(result);
		};
	}

	/**
	 * Logout
	 */
	public logout(refresh?: boolean): void {
		this.tokenStorage.clear();
		if (refresh) {
			location.reload();
		}
	}

	/**
	 * Save access data in the storage
	 * @private
	 * @param {AccessData} data
	 */
	private saveAccessData(accessData: AccessData) {
		if (typeof accessData !== 'undefined') {
			this.decodeToken(accessData.access_token);
			this.tokenStorage
				.setAccessToken(accessData.access_token)
			// .setRefreshToken(accessData.refreshToken)
 				.setUserRoles(accessData.userDetails.authorities);
				this.onCredentialUpdated$.next(accessData);
		}
	}
	public containsPermissions (permissions) {
		const userPermissions = this.authenticatedUserPermissions;
		if (userPermissions === undefined) {
			return true;
		}
		return permissions.some(function (v) {
			return userPermissions.indexOf(v) >= 0;
		});
	}
	private decodeToken(token: string) {
        this.accessToken = token;
        const decodedToken = this.jwtHelper.decodeToken(token);
        this.authenticatedUser = new User();
        this.authenticatedUser.username = decodedToken.user_name;
        this.authenticatedUser.id = decodedToken.userDetails.id;
        this.authenticatedUser.personId = decodedToken.userDetails.personId;
        this.authenticatedUser.name = decodedToken.userDetails.name;
        this.authenticatedUser.surname = decodedToken.userDetails.surname;
        this.authenticatedUser.identityNumber = decodedToken.userDetails.identityNumber;
        this.authenticatedUser.email = decodedToken.userDetails.email;
		this.authenticatedUser.phone = decodedToken.userDetails.phone;
//		this.authenticatedUserRoleDetails = decodedToken.userDetails.roleDetails;
		this.authenticatedUserPermissions = decodedToken.userDetails.permissions;
		this.authenticatedUserParentId = decodedToken.userDetails.parentId;
		this.authenticatedUserMemberInfo = decodedToken.userDetails.memberInfo;
		this.authenticatedEmployeeInfo = decodedToken.userDetails.employeeInfo;
  		this.organizationAdmin = decodedToken.userDetails.admin;

		this.unitIdRolesMapping = new Map<number, Role[]>();
		this.roleUnitIdsMapping = new Map<Role, number[]>();
		this.roleParentIdsMapping = new Map<Role, number[]>();
		this.unitIdMapping = new Map<string, NumberPair[]>();
		if(decodedToken.userRoles) {
			decodedToken.userRoles.forEach(element => {
				let convertedRoles: Role[] = [];
				element.roles.forEach(r => {
					const convertedRole: Role = RoleConverter.convert(r);
					convertedRoles.push(convertedRole);
					if(!this.roleUnitIdsMapping.get(convertedRole)) {
						this.roleUnitIdsMapping.set(convertedRole, []);
					}
					this.roleUnitIdsMapping.get(convertedRole).push(element.unitId);
					
					if(!this.roleParentIdsMapping.get(convertedRole)) {
						this.roleParentIdsMapping.set(convertedRole, []);
					}
					this.roleParentIdsMapping.get(convertedRole).push(element.parentId);
				} );
				this.unitIdRolesMapping.set(element.unitId, convertedRoles);
				let unitIds: NumberPair[] = this.unitIdMapping.get(element.unitType);
				if(!unitIds) {
					this.unitIdMapping.set(element.unitType, [{id1: element.unitId, id2: element.parentId}]);
				} else {
					unitIds.push({id1: element.unitId, id2: element.parentId});
					this.unitIdMapping.set(element.unitType, unitIds);
				}
			});
		}

   	}

	/**
	 * Submit registration request
	 * @param {Credential} credential
	 * @returns {Observable<any>}
	 */
	public register(credential: Credential): Observable<any> {
		// dummy token creation
		credential = Object.assign({}, credential, {
			accessToken: 'access-token-' + Math.random(),
			refreshToken: 'access-token-' + Math.random(),
			roles: ['USER'],
		});
		return this.http.post(this.API_URL + this.API_ENDPOINT_REGISTER, credential)
			.pipe(catchError(this.handleError('register', []))
		);
	}

	/**
	 * Submit forgot password request
	 * @param {Credential} credential
	 * @returns {Observable<any>}
	 */
	public requestPassword(credential: Credential): Observable<any> {
		return this.http.get(this.API_URL + this.API_ENDPOINT_LOGIN + '?' + this.util.urlParam(credential))
			.pipe(catchError(this.handleError('forgot-password', []))
		);
	}

	public hasOnlyFarmerRole(): boolean {
		return this.hasAnyOfTheRoles([Roles.FARMER]) && this.unitIdRolesMapping.size === 1;
	}

	public getHighestRole(): string {
		if (this.unitIdRolesMapping === null || this.unitIdRolesMapping === undefined) {
			return 'GUEST';
		}
		if (this.hasAnyOfTheRoles([Roles.ADMIN])) {
			return Roles.ADMIN.name;
		}
		if (this.hasAnyOfTheRoles([Roles.DIRECTORATE_ADMIN])) {
			return Roles.DIRECTORATE_ADMIN.name;
		}
		if (this.hasAnyOfTheRoles([Roles.DIRECTORATE])) {
			return Roles.DIRECTORATE.name;
		}
		if (this.hasAnyOfTheRoles([Roles.COOP_ADMIN])) {
			return Roles.COOP_ADMIN.name;
		}
		if (this.hasAnyOfTheRoles([Roles.COOP])) {
			return Roles.COOP.name;
		}
		if (this.hasAnyOfTheRoles([Roles.COOP_ACCOUNTANT])) {
			return Roles.COOP_ACCOUNTANT.name;
		}
		if (this.hasAnyOfTheRoles([Roles.COOP_GUARD])) {
			return Roles.COOP_GUARD.name;
		}
		if (this.hasAnyOfTheRoles([Roles.FARMER])) {
			return Roles.FARMER.name;
		}
	}

	public deleteAccount(credential: Credential): Observable<any> {
		let httpHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
		httpHeaders = httpHeaders.set(InterceptorSkipHeader, '');
		httpHeaders = httpHeaders.append('Authorization', 'Basic ' + btoa(TOKEN_AUTH_USERNAME + ':' + TOKEN_AUTH_PASSWORD));
		let body = `username=${encodeURIComponent(credential.username)}`;
			body += `&password=${encodeURIComponent(credential.password)}`;
			body += `&grant_type=password`;

			return this.http.post<AccessData>(AuthenticationService.AUTH_TOKEN, body, { headers: httpHeaders}).pipe(
				map((result: any) => {
					if (result instanceof Array) {
						return result.pop();
					}
					return result;
				}),
				tap(this.saveAccessData.bind(this)),
				catchError(this.handleError('login', [undefined]))
			);
	}

	// COOP BASED ROLE CHECKS
	public hasRolesInDifferentUnits(roles: Role[]): boolean {
		let multipleMemberShip = false;
		roles.forEach(role => {
			if (this.roleUnitIdsMapping.get(role) && this.roleUnitIdsMapping.get(role).length >1 ){
				multipleMemberShip = true;
			}
		});
		return multipleMemberShip;		
	}
	public hasAdminRole() {
		return this.hasRole(Roles.ADMIN) ;
	}
	public hasCoopAdminAccess(coopId: number) {
		return this.hasRole(Roles.ADMIN) || this.hasAccess(coopId, [Roles.COOP_ADMIN]);
	}

	public hasCoopGuardAccess(coopId: number) {
		return this.hasRole(Roles.ADMIN) || this.hasAccess(coopId, [Roles.COOP_GUARD]);
	}

	public hasCoopAccountantAccess(coopId: number) {
		return this.hasRole(Roles.ADMIN) || this.hasAccess(coopId, [Roles.COOP_ACCOUNTANT]);
	}


	public hasCoopAccess(coopId: number) {
		return this.hasRole(Roles.ADMIN) || this.hasAccess(coopId, [Roles.COOP, Roles.COOP_GUARD, Roles.COOP_ADMIN]);
	}

	public hasDirectorateAdminAccess(directorateId: number) {
		return this.hasRole(Roles.ADMIN) || this.hasAccess(directorateId, [Roles.DIRECTORATE_ADMIN]);
	}

	public hasDirectorateAccess(directorateId: number) {
		return this.hasRole(Roles.ADMIN) || this.hasAccess(directorateId, [Roles.DIRECTORATE, Roles.DIRECTORATE_ADMIN]);
	}

	public hasAccessForRole(unitId: number, roles: string[]) : boolean {

		let convertedRoles: Role[] = [];
		if(roles) {
			roles.forEach(r => {
				convertedRoles.push(RoleConverter.convert(r));
			});
		}
		return this.hasAccess(unitId, convertedRoles);
	}

	public hasAccess(unitId: number, roles: Role[]) : boolean {
		if(this.hasAdminRole()) {
			return true;
		}
		let includes = false;
		const coopIds = this.getUnitIdsWithRoles(roles);
		if(coopIds.some (r => r === unitId )) {
			includes = true;
		}
		/*
		if(this.unitIdRolesMapping) {
			let allowedRoles :Role[];
			allowedRoles = this.unitIdRolesMapping.get(unitId);
			if(allowedRoles) {
				allowedRoles.forEach(element => {
					if(roles.some (r => r.id === element.id )) {
						includes = true;
					}
				});
				
			} 
		}*/
		return includes;
	}

	public hasRole(role: Role) : boolean {
		return this.hasAnyOfTheRoles([role])
	}


	public hasOneOfTheRoles(roles: string[]) : boolean {
		let convertedRoles: Role[] = [];
		if(roles) {
			roles.forEach(r => {
				convertedRoles.push(RoleConverter.convert(r));
			});
		}
		return this.hasAnyOfTheRoles(convertedRoles);
	}

	public hasAnyOfTheRoles(roles: Role[]) : boolean {
		let includes = false;
		if(this.unitIdRolesMapping) {
			this.unitIdRolesMapping.forEach((allowedRules: Role[], parentId: number) => {
				allowedRules.forEach((role: Role) => {
					if(roles.some (r => r.id === role.id )) {
						includes = true;
					}
				});
			});

		}
		return includes;
	}
	public getParentIds(unitType: UnitType) : number[] {
		let parentIds :number[] = [];
		if(this.unitIdMapping){
			let unitParentPairs: NumberPair[] = this.unitIdMapping.get(unitType.name);
			if(unitParentPairs) {
					unitParentPairs.forEach(p => {
						parentIds.push(p.id2);
					
				});
			}
		}
		parentIds = parentIds.sort((a, b) => a - b);
		parentIds = this.removeDuplicates(parentIds);
		return parentIds;
	}

	public getUnitIds(unitType: UnitType) : number[] {
		let unitIds :number[] = [];
		if(this.unitIdMapping){
			let unitParentPairs: NumberPair[] = this.unitIdMapping.get(unitType.name);
			if(unitParentPairs) {
				unitParentPairs.forEach(p => {
					unitIds.push(p.id1);
				});
			}
		}
		unitIds = unitIds.sort((a, b) => a - b);
		unitIds = this.removeDuplicates(unitIds);
		return unitIds;
	}

	public getUnitIdsWithRoles(roles: Role[]) : number[] {
		let unitIds :number[] = [];
		roles.forEach((role: Role) => {
			if(role === Roles.FARMER && this.roleParentIdsMapping.get(role) ) {
				unitIds = unitIds.concat( this.roleParentIdsMapping.get(role) );
			} else if (this.roleUnitIdsMapping.get(role) ) {
				unitIds = unitIds.concat( this.roleUnitIdsMapping.get(role) );
			}
		});
		unitIds = unitIds.sort((a, b) => a - b);
		unitIds = this.removeDuplicates(unitIds);
		return unitIds;
	}

	private removeDuplicates(arr: number[]){
		return arr.filter((item,
			index) => arr.indexOf(item) === index);
	}

}
