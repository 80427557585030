import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { SafeStyle } from '@angular/platform-browser';
import { Subscription, timer } from 'rxjs';
import { UserService } from '../../../../pages/services/user.service';
import { Roles } from '../../../../pages/models/constants/role';

@Component({
	selector: 'm-user-account',
	templateUrl: './user-account.component.html',
	styleUrls: ['./user-account.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class UserAccountComponent implements OnInit {
	public statusTimer;
	public locale: string = 'tr';

	@HostBinding('class')
	// tslint:disable-next-line:max-line-length
	classes = 'm-nav__item m-topbar__user-profile m-topbar__user-profile--img m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light';

	@HostBinding('attr.m-dropdown-toggle') attrDropdownToggle = 'click';

	@Input() avatar: string = './assets/irrimet-web/media/img/misc/user.jpg';
	@Input() avatarBg: SafeStyle = '';

	@ViewChild('mProfileDropdown') mProfileDropdown: ElementRef;
	public authenticatedUser: string;
	public showAllBalance = false;
	public totalBalance: number;
	public availableBalance: number;
	public subscription: Subscription;
	constructor (
		private authService: AuthenticationService,
		private userService: UserService,
		private cdr: ChangeDetectorRef
	) {

	}

	ngOnInit (): void {
		if (this.authService.authenticatedUser !== null) {
			this.authenticatedUser = this.authService.authenticatedUser.name + ' ' + this.authService.authenticatedUser.surname;
			if (this.authService.hasRole(Roles.FARMER)) {
				this.initiateTimer();
			}
		}
	}

	public initiateTimer() {
        this.statusTimer = timer(0, 30000);
        this.subscription = this.statusTimer.subscribe((t) => {

            this.fetchMe();
        });
    }

	private fetchMe() {
		this.userService.fetchMe().then(
        (data: any) => {
            if (data !== null) {
				this.availableBalance = '' + data.availableBalance ? data.availableBalance : 0;
				this.totalBalance = '' + data.balance ? data.balance : 0;
				if(data.availableBalance === data.balance) {
					this.showAllBalance = false;
				} else {
					this.showAllBalance = true;
				}
                if (this.cdr != null && !this.cdr['destroyed']) {
                    this.cdr.detectChanges();
                }
             }
        },
        (error) => {
        });
	}
}
